import { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Container, Segment, Icon, Divider, Form } from 'semantic-ui-react'
import { fijar_cocina, fijar_cuarto_caliente, tipo_cocina, tipo_repartidores, iniciar } from '../actions'
import { eliminar_sesion } from '../actions/SessionActions'
import { fijar_sucursal, isSuccess } from '../api'
import { correo_fs, es_cocina_fs, es_modo_cocina_fs, sucursales_fs, sucursal_actual_fs } from '../reducers'
import { DASHBOARD } from '../utils/Routes'

const Configuracion = (props) => {
  const { history, cocina, modo_cocina, email, eliminar_sesion, 
    fijar_cocina, fijar_cuarto_caliente, sucursales, sucursal, iniciar } = props

  const [busy, setBusy] = useState(false)

  const back = () => history.push(DASHBOARD)
  const logout = async () => {
    await eliminar_sesion()
    back()
  }

  const onClickCocina = () => {
    fijar_cocina()
    back()
  }

  const onClickCuarto = () => {
    fijar_cuarto_caliente()
    back()
  }

  const onSucursalChange = async (e, { value }) => {
    try {
      setBusy(true)
      const res = await fijar_sucursal(value)
      if (isSuccess(res)) {
        await iniciar()
      }
    } catch (e) {
      console.error('onSucursalChange', e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Container>
      <Segment>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Button icon labelPosition="left"  onClick={back}>
            <Icon name="arrow left" />
            Volver
          </Button>
          <label>Tu correo es <strong>{email}</strong></label>
        </div>
        <Divider />
        <p>Selecciona para cambiar la sucursal</p>
        <Form.Select
          fluid
          value={sucursal}
          onChange={onSucursalChange}
          options={sucursales.map(v => ({ key: v.id, value: v.id, text: v.nombre }))}
          placeholder='Selecciona la Sucursal'
          loading={busy}
        />
        <Divider />
        {modo_cocina && <p>Selecciona cocina o cuarto caliente para ver en el dashboard</p>}
        {modo_cocina && <Button.Group fluid disabled={busy}>
          <Button primary={cocina} onClick={onClickCocina}>Cocina</Button>
          <Button primary={!cocina} onClick={onClickCuarto}>Cuarto Caliente</Button>
        </Button.Group>}
      </Segment>
      <Segment>
        <p>Presiona para salir de la sesion</p>
        <Button color="red" fluid onClick={logout} disabled={busy}>Salir de la app</Button>
      </Segment>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  cocina: es_cocina_fs(state),
  email: correo_fs(state),
  modo_cocina: es_modo_cocina_fs(state),
  sucursales: sucursales_fs(state),
  sucursal: sucursal_actual_fs(state)
})

export default connect(mapStateToProps, { 
  tipo_cocina, tipo_repartidores, eliminar_sesion, fijar_cuarto_caliente, fijar_cocina, iniciar
})(Configuracion)