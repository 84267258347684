import { useEffect } from 'react'
import { HashRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import AppHeader from './components/AppHeader';
import { Cocina, Configuracion, Repartidor, Login, DetallePedido } from "./screens";
import { CONFIGURACION, DETALLE_PEDIDO } from "./utils/Routes";
import { autorizado_fs, es_modo_cocina_fs } from "./reducers";
import { iniciar } from './actions';

import './App.css';


function App({ modo_cocina, autorizado, iniciar }) {
  useEffect(() => iniciar(), [])

  if (!autorizado) {
    return <Login />
  }

  return (
    <HashRouter>
      <Route component={AppHeader} />
      <div className="App">
        <Switch>
          <Route path={CONFIGURACION} component={Configuracion} />
          <Route path={DETALLE_PEDIDO} component={DetallePedido} />
          <Route component={modo_cocina ? Cocina : Repartidor} />
        </Switch>
      </div>
    </HashRouter>
  );
}

const mapStateToProps = (state) => ({
  modo_cocina: es_modo_cocina_fs(state),
  autorizado: autorizado_fs(state)
})

export default connect(mapStateToProps, { iniciar })(App)
