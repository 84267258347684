import { combineReducers } from 'redux'
import { ELIMINAR_SESION, FIJAR_COCINA, FIJAR_CUARTO_CALIENTE, FIJAR_PEDIDOS, FIJAR_SESSION, FIJAR_TIPO_COCINA, FIJAR_TIPO_REPARTIDORES, FIJAR_TITULO, NUEVA_SESION } from '../actions/Types'
import { isEmpty } from '../utils'
import createReducer from './createReducer'

export const COCINA = 'COCINA'
export const CUARTO_CALIENTE = 'CUARTO_CALIENTE'


const initialSession = {
    cocina: COCINA,
    token: '',
    email: '',
    permisos: 0,
    sucursal: null,
    sucursales: []
}

const session = createReducer(initialSession, {
    [FIJAR_COCINA]: (state) => ({ ...state, cocina: COCINA}),
    [FIJAR_CUARTO_CALIENTE]: (state) => ({ ...state, cocina: CUARTO_CALIENTE}),
    [NUEVA_SESION]:  (state, { token, email }) => ({ ...state, token, email }),
    [FIJAR_SESSION]: (state, { permisos, sucursal, sucursales }) => ({
        ...state,
        permisos,
        sucursal,
        sucursales
    }),
    [ELIMINAR_SESION]: () => ({ ...initialSession })
})

const pedidos = createReducer([], {
    [FIJAR_PEDIDOS]: (state, { data }) => [ ...data ]
})

const initialApp = {
    titulo: 'Comandapp'
}

const app = createReducer(initialApp, {
    [FIJAR_TITULO]: (state, { titulo }) => ({ ...state, titulo })
})

export function correo_fs(state) {
    return state.session.email
}

export function es_cocina_fs(state) {
    return state.session.cocina === COCINA
}

export function es_modo_cocina_fs(state) {
    return state.session.permisos > 64
}

export function token_fs(state) {
    return state.session.token
}

export function nombre_sucursal_fs(state) {
    const id = state.session.sucursal
    const sucursal = state.session.sucursales.find(v => v.id === id)
    return sucursal !== null ? sucursal.nombre : ''
}

export function autorizado_fs(state) {
    return !isEmpty(state.session.token)
}

export function pedidos_fs(state) {
    return state.pedidos
}

export function sucursales_fs(state) {
    return state.session.sucursales
}

export function sucursal_actual_fs(state) {
    return state.session.sucursal
}

export default combineReducers({ session, pedidos, app })
