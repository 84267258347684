import { ELIMINAR_SESION, FIJAR_SESSION, NUEVA_SESION } from './Types'
import { fijar_sucursal, isSuccess, login, logout, obtenerSesion } from '../api'

export function sessionLogin(data) {
  return async (dispatch) => {
    const res = await login(data)
    if (res.status === 'success') {
      dispatch({
        type: NUEVA_SESION,
        token: res.data,
        email: data.email
      })
      await refrescar_sesion()(dispatch)
    }
  } 
}

export const eliminar_sesion = () => (dispatch) => logout().finally(() =>
  dispatch(destroySession())
)

export function refrescar_sesion() {
  return (dispatch) => obtenerSesion().then(res => autorizado(dispatch, res)).then(res => {
    dispatch({
      type: FIJAR_SESSION,
      ...res.data
    })
    return res
  })
}

export function autorizado(dispatch, res) {
  if (res.status === 'auth') {
    dispatch(destroySession())
    return Promise.reject(res.data)
  }
  return res
}

function destroySession() {
  return {
    type: ELIMINAR_SESION
  }
}
