import { fetch_pedidos, fetch_pedidos_cocina, isSuccess } from "../api";
import { autorizado_fs, es_modo_cocina_fs, nombre_sucursal_fs, pedidos_fs } from "../reducers";
import { refrescar_sesion } from "./SessionActions";
import { FIJAR_COCINA, FIJAR_CUARTO_CALIENTE, FIJAR_PEDIDOS, FIJAR_TIPO_COCINA, FIJAR_TIPO_REPARTIDORES, FIJAR_TITULO } from "./Types";



export function iniciar() {
  return async (dispatch, getState) => {
    try {
      notificaciones()
      if (autorizado_fs(getState())) {
        await refrescar_sesion()(dispatch)
        dispatch(fijar_titulo(nombre_sucursal_fs(getState())))
        await refrescar_pedidos()(dispatch, getState)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

function notificaciones() {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else {
    Notification.requestPermission();
  }
}

function notificar_pedidos(modo_cocina) {
  var options = {
    body: "Nuevos pedidos",
    dir: "ltr"
  };
  new Notification(modo_cocina ?  "Cocina" : "Repartidor", options);
  const audio = new Audio('jobdone.mp3');
  audio.play();
}

export function refrescar_pedidos() {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      const pedidos = pedidos_fs(state).map(v => v._id)
      const modo_cocina = es_modo_cocina_fs(state)
      const _invoke = modo_cocina ? fetch_pedidos_cocina : fetch_pedidos
      const res = await _invoke()
      //console.log(res)
      if (isSuccess(res)) {
        const nuevos = res.data.map(v => v._id)
        if (nuevos.length > 0 && nuevos.some(v => pedidos.find(k => v === k) === undefined)) {
          notificar_pedidos(modo_cocina)
        }
        dispatch({
          type: FIJAR_PEDIDOS,
          data: res.data
        })
      }
    } catch (e) {
      console.error('refrescar_pedidos', e)
    }
  }
}

export function fijar_titulo(titulo) {
  return {
    type: FIJAR_TITULO,
    titulo
  }
}


export function tipo_cocina() {
  return {
    type: FIJAR_TIPO_COCINA
  }
}

export function tipo_repartidores() {
  return {
    type: FIJAR_TIPO_REPARTIDORES
  }
}

export function fijar_cocina() {
  return {
    type: FIJAR_COCINA
  }
}

export function fijar_cuarto_caliente() {
  return {
    type: FIJAR_CUARTO_CALIENTE
  }
}