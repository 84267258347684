import moment from 'moment';
import numeral from 'numeral'

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;


export function isEmpty(s) {
  return !s || s === null || (s.trim ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function testEmail(email) {
  return EMAIL_REG.test(email);
}

export function clp(v) {
  return numeral(v).format('$0,0')
}


export function str(v) {
  return v && v !== null ? v.toString() : ''
}

export function reduceProducts(t, v) {
  if (v.subproducts) {
    t += v.subproducts.reduce(reduceProducts, 0) * v.quantity
  }

  return t + (v.price * v.quantity)
}

export function fecha(v) {
  return moment(v).format('DD/MM/YYYY HH:mm:ss')
}

export function primary_color() {
  return process.env.REACT_APP_PRIMARY_COLOR || 'black'
}