import { get, post, del } from "./Methods";

export const sendEmail = (email) => post('/api/email', { email })
export const login = (body) => post('/api/login', body)
export const logout = () => del('/api/v1/logout')
export const obtenerSesion = () => get('/api/v1/session')
export const fijar_sucursal = (sucursal) => post('/api/v1/user/sucursal', { sucursal })

export const fetch_pedidos = () => get('/api/v1/temaki/pedidos')
export const marcar_entrega = (_id) => post('/api/v1/temaki/order/done', { _id })
export const pagar = (id) => post('/api/v1/order/pay', { id })

export const fetch_pedidos_cocina = () => get('/api/v1/order/WORKING')
export const cambiar_estado = (_id, estado, current) => post('/api/v1/order/status', { _id, estado, current })
export const hotroom_flag = (id) => post('/api/v1/order/hotroom', { id })

export function isSuccess(res) {
  return res.status === 'success'
}