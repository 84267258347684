import React, { Component } from 'react';
import { Button, Form, Grid, Header, Message, Segment, Image, Icon, Container } from 'semantic-ui-react';
import { connect } from 'react-redux'
import moment from 'moment'

import { isEmpty, testEmail, int } from '../utils'
import { sessionLogin } from '../actions/SessionActions'
import { isSuccess, sendEmail } from '../api'
import { MensajeError } from '../components/Mensajes';


function sortSucursles(a, b) {
  return a.id - b.id
}

class Login extends Component {
  state = {
    email:'',
    code: '',
    step: 0,
    loading: false,
    error: '',
    selected: null,
    sucursales: []
  }

  sendEmail = async (e) => {
    e.preventDefault()
    this._clear()
    this.toggleLoading()
    try {
      const res = await sendEmail(this.state.email.trim())
      if (isSuccess(res)) {
        const sucursales = res.data
        if (sucursales.length === 0) {
          this._onError("Usuario no autorizado")
        } else {
          this.setState({
            step: 1,
            sucursales: sucursales.sort(sortSucursles),
            selected: sucursales[0].id
          })
        }
      } else {
        this._onError('Problemas enviando el correo, intente mas tarde.')
      }
    } catch (e) {
      this._onError(e)
    } finally {
      this.toggleLoading()
    }
  }

  login = async (e) => {
    e.preventDefault()
    this.toggleLoading()
    const { email, code, selected } = this.state

    try {
      const data = {
        email: email.trim(),
        codigo: int(code),
        sucursal: int(selected)
      }

      const res = await this.props.sessionLogin(data)
      if (!isSuccess(res)) {
        this.setState({ error: res.data, loading: false })
      }
    } catch (e) {
      this._onError(e)
    }
  }

  back = () => this.setState({step: 0, code: ''})
  toggleLoading = () => this.setState({ loading: !this.state.loading })

  _onError = (e) => {
    console.log(e)
    this.setState({error: e.message ? e.message : e })
  }

  _clear = () => this.setState({error: ''})
  _email = () => !testEmail(this.state.email) || this.state.loading
  _code = () => isEmpty(this.state.code) || isEmpty(this.state.selected) || this.state.loading
  onEmailChange = (e) => this.setState({ email: e.target.value })
  onCodeChange = (e) => this.setState({ code: e.target.value })
  onSucursalChange = (e, { value }) => this.setState({ selected: value })

  render() {
    const { email, code, step, loading, error, sucursales, selected } = this.state

    return (
      <Container>
        <Grid textAlign='center' style={{ height: '70vh', marginTop: 32 }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='black' textAlign='center'>
              <Image src={process.env.REACT_APP_LOGO} /> {process.env.REACT_APP_TITULO}
            </Header>
            <Form size='large' loading={loading}>
              <MensajeError content={error} />
              {step === 0 ?
                <EmailSegment email={email} onEmailChange={this.onEmailChange} onClick={this.sendEmail} /> :
                <CodeSegment code={code} onCodeChange={this.onCodeChange} onClick={this.login}
                  onBackClick={this.back} sucursales={sucursales} sucursal={selected} onSucursalChange={this.onSucursalChange} />
              }
            </Form>
            <Message attached='bottom' warning>
              <Icon name='help' />
              {step === 0 ? 'Recibiras un correo con el código para ingresar.' : `Ingresa el codigo que enviamos al correo ${email}`}
            </Message>
            <Icon name="copyright" /> {process.env.REACT_APP_TITULO} - <small>{moment().format('YYYY')}</small>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

const EmailSegment = ({ email, onEmailChange, onClick }) => {
  const error = email.length > 0 && !testEmail(email)
  return (
    <Segment stacked>
      <Form.Input type="email" error={error} fluid icon='mail' iconPosition='left' placeholder='correo@proveedor.com' value={email} onChange={onEmailChange} />
      <Button color='black' fluid size='large'onClick={onClick} disabled={error || isEmpty(email)}>
        Continuar
      </Button>
    </Segment>
  )
}

const CodeSegment = ({ code, onCodeChange, onClick, onBackClick, sucursales, sucursal, onSucursalChange }) => {
  const error = isNaN(code)
  return (
    <Segment stacked>
      <Form.Select
        fluid
        value={sucursal}
        onChange={onSucursalChange}
        options={sucursales.map(v => ({ key: v.id, value: v.id, text: v.nombre }))}
        placeholder='Selecciona la Sucursal'
      />
      <Form.Input fluid icon='unhide' type="number" iconPosition='left'
        placeholder='Ingresa el código' error={error} value={code} onChange={onCodeChange} />
      <Button.Group vertical fluid labeled icon>
        <Button color='black' size='large' content="Ingresar" icon="sign in" onClick={onClick} disabled={error || isEmpty(code)} />
        <Button size="large" icon="chevron left" content="Volver" onClick={onBackClick} />
      </Button.Group>
    </Segment>
  )
}

const mapStateToProps = ({ session }) => ({
  authorized: !isEmpty(session.token)
})

export default connect(mapStateToProps, { sessionLogin })(Login)