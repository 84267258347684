import { token_fs } from '../reducers'
import { store } from '../Store'

const host = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HOST : 'http://192.168.0.25:8000'
//const host = 'http://localhost:8000'

export function get(path, headers) {
  return request(path, 'GET', undefined, headers)
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function put(path, body) {
  return request(path, 'PUT', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

function request(path, method, body, headers) {
  const token = token_fs(store.getState())

  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': token,
      ...headers
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(success)//.then(lag)
}
/*
function lag(v) {
  return new Promise(res => {
    setTimeout(() => res(v), 500)
  })
}*/

function success(response) {
  if (response.status === 401) {
    return ({
      status: 'auth',
      data: 'No autorizado'
    })
  }
  if (response.status !== 200) {
    return Promise.reject(response)
  }
  return response.json()
}