import React, { Component } from "react"
import { connect } from "react-redux"
import { Button, Container, Item, Segment, Label, Icon, Statistic } from "semantic-ui-react"
import { refrescar_pedidos } from "../actions"
import { pedidos_fs } from "../reducers"
import { clp, fecha, reduceProducts } from "../utils"
import { medioDePago } from "../utils/MedioPago"
import { DETALLE_PEDIDO } from "../utils/Routes"


const POR_ENTREGRAR = undefined
const ENTREGADO = 'DONE'

class Repartidor extends Component {
  state = {
    estado: POR_ENTREGRAR
  }

  componentDidMount() {
    this._interval = setInterval(this.props.refrescar_pedidos, 5000);
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  detalle = (_id) => this.props.history.push(DETALLE_PEDIDO.replace(':id', _id))
  porEntregar = () => this.setState({ estado: POR_ENTREGRAR })
  entregado = () => this.setState({ estado: ENTREGADO })

  render() {
    const { estado } = this.state
    const pedidos = this.props.pedidos.filter(o => o.deliveryStatus === estado)

    return (
      <Container>
        <Button.Group fluid>
          <Button primary={estado === POR_ENTREGRAR} onClick={this.porEntregar}>Por despachar</Button>
          <Button primary={estado === ENTREGADO} onClick={this.entregado}>Despachados</Button>
        </Button.Group>
        {estado === ENTREGADO &&
        <Segment>
          <Statistic.Group size="mini" widths={3}>
            <Statistic>
              <Statistic.Value>{clp(pedidos.reduce((t, o) => (t + o.deliveryCost), 0))}</Statistic.Value>
              <Statistic.Label>Despachos</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(pedidos.map(v => v.products.reduce(reduceProducts, 0)).reduce((t, v) => t + v, 0))}</Statistic.Value>
              <Statistic.Label>Productos</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(pedidos.map(v => v.products.reduce(reduceProducts, v.deliveryCost)).reduce((t, v) => t + v, 0))}</Statistic.Value>
              <Statistic.Label>Total</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        }
        {pedidos.length > 0 && <Segment>
          <Item.Group divided>
            {pedidos.map(v  => (
              <Item key={v._id} onClick={() => this.detalle(v._id)} className="cs-pointer" style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Item.Meta>N° {v.index}</Item.Meta>
                  <Item.Meta>{fecha(v.datetime)}</Item.Meta>
                </div>
                <Item.Header><Icon name="address book outline" /> {v.user.address}</Item.Header>
                <Item.Header><Icon name="phone" /> +56 - {v.user.phone}</Item.Header>
                <Item.Description><Icon name="user" /> {v.user.name} {v.user.apellido}</Item.Description>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Item.Extra>
                    <Label.Group>
                      <Label>{medioDePago(v.payment)}</Label>
                      <Label color="red">despacho {clp(v.deliveryCost)}</Label>
                    </Label.Group>
                  </Item.Extra>
                  <Item.Header>{clp(v.products.reduce(reduceProducts, v.deliveryCost))}</Item.Header>
                </div>
              </Item>
            ))}
          </Item.Group>
        </Segment>}
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  pedidos: pedidos_fs(state)
})

export default connect(mapStateToProps, { refrescar_pedidos })(Repartidor)