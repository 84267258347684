import React, { Component } from "react"
import { connect } from "react-redux"
import { Container, Segment, Label, Icon, Item, Divider, Button, Statistic } from "semantic-ui-react"
import { refrescar_pedidos } from "../actions"
import { marcar_entrega, pagar } from "../api"
import { pedidos_fs } from "../reducers"
import { clp, reduceProducts } from "../utils"
import { medioDePago } from "../utils/MedioPago"


class DetallePedido extends Component {
  state = {
    busy: false
  }

  componentDidMount() {
    this.props.refrescar_pedidos()
  }

  entregar = async () => {
    try {
      const _id = this.props.match.params.id
      const pedido = this.props.pedidos.find(v => v._id === _id)
  
      this.toggleBusy()
      await marcar_entrega(pedido._id)
      this.props.history.goBack()
    } catch (e) {
      console.error(e)
      this.toggleBusy()
    }
  }

  pagar = async () => {
    try {
      const _id = this.props.match.params.id
      const pedido = this.props.pedidos.find(v => v._id === _id)

      this.toggleBusy()
      await pagar(pedido._id)
      await this.props.refrescar_pedidos()
    } catch (e) {
      console.error(e)
    } finally {
      this.toggleBusy()
    }
  }

  toggleBusy = () => this.setState({ busy: !this.state.busy })

  render() {
    const busy = this.state.busy
    const _id = this.props.match.params.id
    const pedido = this.props.pedidos.find(v => v._id === _id)
    const { user, products } = pedido

    return (
      <Container>
        <Segment>
          <Campo label="Número del pedido" icon="dot circle" value={pedido.index} />
          <Divider />
          <CampoTelefono label="Teléfono" icon="phone" value={user.phone}/>
          <Divider />
          <CampoDireccion label="Dirección" icon="address book outline" value={user.address} />
          <Divider />
          <Campo label="Nombre" icon="user" value={user.name + " " + user.apellido} />
          <Divider />
          <Campo label="Comentario" icon="comment" value={pedido.coment} />
          <Divider />
          <Label.Group>
            <Label color="blue">{medioDePago(pedido.payment)}</Label>
            {pedido.pay && <Label color="green">Pagado</Label>}
          </Label.Group>
        </Segment>
        <Segment>
          <Item.Group divided>
            {products.map((v, i) => (
              <Item key={i}>
                <Item.Content>
                  <Item.Header>{v.title} <Label color="red" circular>{v.quantity}</Label></Item.Header>
                  <Item.Meta>{v.detail}</Item.Meta>
                  <Item.Description>{clp(v.price)}</Item.Description>
                  <ul>
                    {v.subproducts && v.subproducts.map((s, k) => (
                      <li key={k}>{s.title} {clp(s.price)}</li>
                    ))}
                  </ul>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        </Segment>
        <Segment raised>
          <Statistic.Group size="tiny" widths={2}>
            <Statistic>
              <Statistic.Value>{clp(pedido.deliveryCost)}</Statistic.Value>
              <Statistic.Label>Despacho</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{clp(products.reduce(reduceProducts, pedido.deliveryCost))}</Statistic.Value>
              <Statistic.Label>Total</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <div style={{ display: 'flex', flexDirection: "column" }}>
          {pedido.deliveryStatus !== 'DONE' &&
            <Button primary style={styles.boton} onClick={this.entregar} loading={busy} disabled={busy}>Entregar</Button>}
          {/*!pedido.pay && 
            <Button color="green" style={styles.boton} onClick={this.pagar} loading={busy} disabled={busy}>Marcar como Pagado</Button>*/}
          <Button style={styles.boton} onClick={this.props.history.goBack}>Volver</Button>
        </div>
      </Container>
    )
  }
}

const Campo = ({ label, icon, value }) => (
  <div>
    <label><Icon name={icon} /> {label}</label>
    <h4 style={{ marginTop: 8, marginLeft: 24 }}>{value}</h4>
  </div>
)

const CampoTelefono = ({ label, icon, value }) => (
  <div>
    <label><Icon name={icon} /> {label}</label>
    <h4 style={{ marginTop: 8, marginLeft: 24 }}><a href={"tel:+56" + value}>+56 - {value}</a></h4>
  </div>
)

const CampoDireccion = ({ label, icon, value }) => (
  <div>
    <label><Icon name={icon} /> {label}</label>
    <h4 style={{ marginTop: 8, marginLeft: 24 }}><a href={"https://maps.google.com/?q=" + value}>{value}</a></h4>
  </div>
)

const styles = {
  boton: {
    margin: 2
  }
}

const mapStateToProps = (state) => ({
  pedidos: pedidos_fs(state)
})

export default connect(mapStateToProps, { refrescar_pedidos })(DetallePedido)