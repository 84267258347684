import moment from "moment"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Button, Container, Grid, Item, Label, List, Menu, Segment } from "semantic-ui-react"
import { refrescar_pedidos } from "../actions"
import { cambiar_estado, hotroom_flag } from "../api"
import { es_cocina_fs, pedidos_fs } from "../reducers"
import { primary_color } from "../utils"


const TODOS = 'TODOS'
const DELIVERY = "DELIVERY"
const LOCAL = "RETIRO"
const RESTAURANT = "RESTAURANT"

class Cocina extends Component {
  state = {
    estado: TODOS,
    busy: false
  }

  componentDidMount() {
    this._interval = setInterval(this.props.refrescar_pedidos, 5000);
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  fijarEstado = (estado) => this.setState({ estado })
  filtrar = (v) => {
    if (this.props.cuarto_caliente && v.hotroom) { 
      return false
    }
    return (v.type === this.state.estado || this.state.estado === TODOS)
  }

  cambiarEstado = async (_id, estado) => {
    try {
      this.setState({ busy: true })
      if (this.props.cuarto_caliente) {
        await hotroom_flag(_id)
      } else {
        await cambiar_estado(_id, 'DONE', estado)
      }
      this.props.refrescar_pedidos()
    } catch (e) {
      console.error('cambiar estado', e)
    } finally {
      this.setState({ busy: false })
    }
  }

  render() {
    const { estado, busy } = this.state
    const { pedidos, cuarto_caliente } = this.props
    const data = pedidos.filter(this.filtrar)
    const retiro_size = pedidos.filter(v => v.type === LOCAL).length
    const domicilio_size = pedidos.filter(v => v.type === DELIVERY).length
    const restaurant_size = pedidos.filter(v => v.type === RESTAURANT).length

    return (
      <Container>
        {/*<MenuEstados />*/}
        {<Menu widths={4} inverted color={primary_color()}>
          <EstadoItem label="Todos" color="blue" size={pedidos.length} estado={TODOS} onClick={this.fijarEstado} actual={estado} />
          <EstadoItem label="Retiro" color="violet" size={retiro_size} estado={LOCAL} onClick={this.fijarEstado} actual={estado} />
          <EstadoItem label="Domicilio" color="brown" size={domicilio_size} estado={DELIVERY} onClick={this.fijarEstado} actual={estado} />
          <EstadoItem label="Restaurante" color="grey" size={restaurant_size} estado={RESTAURANT} onClick={this.fijarEstado} actual={estado} />
    </Menu>}
        <Segment>
          <Item.Group divided>
            {data.sort(sortPedidos).map(v => (
              <PedidoItem key={v._id} {...v} finalizar={this.cambiarEstado} busy={busy} cuarto_caliente={cuarto_caliente}/>
            ))}
          </Item.Group>
        </Segment>
      </Container>
    )
  }
}

const PedidoItem = ({ _id, status, products, user, comment, type, index, datetime, finalizar, hotroom, busy, cuarto_caliente }) => {
  const onClick = () => finalizar(_id, status)
  return (
    <Item>
      <Item.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <Item.Meta>N° {index}</Item.Meta>
          <Item.Meta>{moment(datetime).fromNow()}</Item.Meta>
        </div>
        <Item.Header>{user.name} {user.apellido}</Item.Header>
        <Item.Meta>{user.address}</Item.Meta>
        <Label color="green">{labelTipo(type)}</Label>
        <Item.Description>{comment}</Item.Description>
          <Item.Group divided>
          {products.map((v, i) => (
            <Item key={i}>
              <Item.Content>
                <Item.Header>{v.title} <Label circular color="red">{v.quantity}</Label></Item.Header>
                <Item.Description>{v.detail}</Item.Description>
                {v.products &&
                <List bulleted>
                  {v.products.map((k, ind) => (
                    <List.Item key={ind}>
                      <List.Content>
                        <List.Header>{k.title}</List.Header>
                        <List.Description>{k.detail}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
                }
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
        {cuarto_caliente ? <CuartoCalienteBottom busy={busy} onClick={onClick} /> :
         <CocinaBottom hotroom={hotroom} busy={busy} onClick={onClick} />}
      </Item.Content>
    </Item>
  )
}

const CuartoCalienteBottom = ({ onClick, busy }) => (
  <Button color="blue" fluid onClick={onClick} disabled={busy} loading={busy}>Terminar Cuarto Caliente</Button>
)

const CocinaBottom = ({ hotroom, busy, onClick }) => (
  <Grid>
    <Grid.Column width={3} textAlign="center">
      <Segment compact>
        <p>Cuarto Caliente</p>
        <Label circular empty color={hotroom ? "green" : "red"} size="massive" />
      </Segment>
    </Grid.Column>
    <Grid.Column width={12} verticalAlign="middle">
      <Button color="green" fluid onClick={onClick} disabled={busy || !hotroom} loading={busy}>Finalizar</Button>
    </Grid.Column>
  </Grid>
)

const EstadoItem = ({ estado, label, size, actual, onClick, color }) => {
  const active = actual === estado
  const style = { backgroundColor: color }

  return (
    <Menu.Item active={active} color="blue" onClick={() => onClick(estado)}>
      <div>
        <Label circular size="tiny" color={color}>{size}</Label>
        <div>{label}</div>
      </div>
    </Menu.Item>
  )
}

function labelTipo(type) {
  switch (type) {
    case DELIVERY: return 'Despacho'
    case RESTAURANT: return 'Restaurante'
    default: return 'Retiro'
  }
}

function sortPedidos(a, b) {
  return a.index > b.index ? 1 : -1
}
/*
const MenuEstados = () => {
  return (
    <div style={styles.menu}>
      <div style={{...styles.item, ...styles.bgTodos }}>Todos (2)</div>
      <div style={{ ...styles.item, ...styles.bgRetiro }}>Retiro (1)</div>
      <div style={{ ...styles.item, ...styles.bgDomicilio }}>Domicilio (5)</div>
      <div style={{ ...styles.item, ...styles.bgServir }}>Servir (7)</div>
    </div>
  )
}

const styles = {
  menu: {
    display: 'flex'
  },
  item: {
    flex: 1,
    display: 'flex',
    color: 'white',
    fontWeight: 'bold',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  bgTodos: {
    backgroundColor: '#3498db'
  },
  bgRetiro: {
    backgroundColor: '#795548'
  },
  bgDomicilio: {
    backgroundColor: '#8e44ad'
  },
  bgServir: {
    backgroundColor: '#2c3e50'
  }
}*/

const mapStateToProps = (state) => ({
  pedidos: pedidos_fs(state),
  cuarto_caliente: !es_cocina_fs(state)
})

export default connect(mapStateToProps, { refrescar_pedidos })(Cocina)