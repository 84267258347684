import { connect } from 'react-redux';
import { Icon, Menu } from 'semantic-ui-react';
import { primary_color } from '../utils';
import { CONFIGURACION, DASHBOARD } from '../utils/Routes';


const styles = {
  icon: {
    width: 32,
    height: 32,
  },
  menuItem: {
    textAlign: 'center'
  }
}

const AppHeader = ({ history, titulo }) => {
  //const pathname = history.location.pathname
  const navigate = (path) => {
    history.push(path)
  }

  return (
    <Menu color={primary_color()} inverted fixed="top">
      <Menu.Item onClick={() => navigate(DASHBOARD)}>
        <img src={process.env.REACT_APP_LOGO} alt="logo" style={styles.icon} />
        <h3 style={{ marginTop: 0, marginLeft: 16 }}>{titulo}</h3>
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item onClick={() => navigate(CONFIGURACION)}>
          <div style={styles.menuItem}>
            <Icon name="cog" size="large" />
            <div>Configuración</div>
          </div>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

const mapStateToProps = (state) => ({
  titulo: state.app.titulo
})

export default connect(mapStateToProps)(AppHeader)
/*
function activo(pathname, path) {
  return pathname.indexOf(path) !== -1
}*/