import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import numeral from 'numeral';
import moment from 'moment';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './Store'

import 'semantic-ui-less/semantic.less'
import 'moment/locale/es'
import 'numeral/locales/es'


numeral.locale('es')
moment.locale('es')


const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

ReactDOM.render(
//  <React.StrictMode>
    <Root />
  /*</React.StrictMode>*/,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
