export const NUEVA_SESION = 'NUEVA_SESION'
export const ELIMINAR_SESION = 'ELIMINAR_SESION'
export const FIJAR_SESSION = 'FIJAR_SESSION'

export const FIJAR_TIPO_REPARTIDORES = 'FIJAR_TIPO_REPARTIDORES'
export const FIJAR_TIPO_COCINA = 'FIJAR_TIPO_COCINA'

export const FIJAR_COCINA = 'FIJAR_COCINA'
export const FIJAR_CUARTO_CALIENTE = 'FIJAR_CUARTO_CALIENTE'

export const FIJAR_PEDIDOS = 'FIJAR_PEDIDOS'
export const FIJAR_TITULO = 'FIJAR_TITULO'